<template>
  <div class="content">
    <h1 class="title title--big title--indent title--color">Список пользователей</h1>
    <div class="content__party content__party--start">
      <router-link
        :to="{ name: 'admin-create' }"
        class="button button--light button--no-indent button--circle"
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить пользователя</span>
      </router-link>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    @updateOption="updateLimiter"
                    :closeOnOutsideClick="true">
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>1-{{ $store.state.admin.manager.length }}</span> из <span>{{
            $store.state.admin.manager.all
          }}</span>
        </div>
      </div>
    </div>
    <v-client-table :data="managers" :columns="columns" :options="options" ref="managersTable" class="table-default">
      <div slot="name" slot-scope="props" class="table-default__left">
        {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic }}
      </div>
      <div slot="activity" slot-scope="props">
        {{ props.row.is_active ? 'Да' : 'Нет' }}
      </div>
      <div slot="region_name" slot-scope="props">
        {{ props.row.region_name ? props.row.region_name : 'Федеральный уровень' }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z"
                fill="#2B93E7"/>
              <path
                d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
                fill="#2B93E7"/>
              <path
                d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z"
                fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <router-link :to="{ name: 'admin-user', params: { id: props.row.id }}" class="tooltip__btn">
                  Редактировать
                </router-link>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="deleteManager(props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="$store.state.admin.manager.count > 1"
      v-model="$store.state.admin.manager.page"
      :page-count="$store.state.admin.manager.count"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';

export default {
  components: {dropdown},
  data() {
    return {
      columns: ['id', 'name', 'email', 'date_joined', 'activity', 'region_name', 'tooltip'],
      current_order: 'id',
      options: {
        headings: {
          id: 'ID',
          name: 'ФИО',
          email: 'Логин',
          date_joined: 'Дата создания',
          activity: 'Активность',
          region_name: 'Регион',
          tooltip: ''
        },
        pagination: {show: false},
        sortable: [],
        perPage: 20,
        texts: {
          filter: '',
          filterPlaceholder: 'Поиск по таблице',
          noResults: 'Нет подходящих записей',
          filterBy: '',
          loading: 'Загрузка',
          count: '',
          limit: 'Количество записей на странице:',
          page: 'Страница:'
        },
        rowClassCallback() {
          return 'table-default__row';
        },
      },
      pagination: {
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: this.$store.state.admin.manager.limit},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ]
    };
  },
  computed: {
    managers() {
      return this.$store.state.admin.manager.managers;
    }
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchManagers(1, this.$store.state.admin.manager.limit);
  },
  beforeDestroy() {
    this.updateLimiter({name: 20});
  },
  methods: {
    fetchManagers(page = 1, limit) {
      this.$store.dispatch('admin/manager/GET_LIST', {page, limit})
        .then(response => {
          this.$store.commit('admin/manager/changeCount', response.data.pages);
          this.$store.commit('admin/manager/setAll', response.data.count);
          this.$store.commit('admin/manager/setLength', response.data.results.length);
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${error.message}`
          });
        });
    },
    clickPaginationCallback(page) {
      this.fetchManagers(page, this.$store.state.admin.manager.limit);
    },
    updateLimiter(data) {
      this.$store.commit('admin/manager/changeLimit', data.name);
      this.$refs.managersTable.setLimit(this.$store.state.admin.manager.limit);
      this.fetchManagers(1, this.$store.state.admin.manager.limit);
    },
    returnStart() {
      return (this.$store.state.admin.manager.page * this.$store.state.admin.manager.length) - (this.$store.state.admin.manager.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.$store.state.admin.manager.length - 1;
    },
    deleteManager(id) {
      this.$store.dispatch('admin/manager/DELETE_DATA', id)
        .then(() => {
          document.body.click();
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Пользователь успешно удален'
          });
          this.fetchManagers();
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: 'Ошибка удаления пользователя'
          });
        });
    }
  },
};
</script>
